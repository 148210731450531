
import "../styles/better.css";
import NftCarousel from "./NftCarousel";

import React, {useState, useEffect, useRef} from 'react'
const App = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const imageRef = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              setIsExpanded(true);
              observer.unobserve(imageRef.current);
            }
          },
          { threshold: 0.5 } // Trigger the observer when the image is 50% in view
        );
        observer.observe(imageRef.current);
      }, []);
  return (
    <div className="lol" >
      <div className="ximg" style={{marginTop:"10px"}}>
      <a className="navbar-brand heading-black" href="#">
              <div className="name">XADE</div>
            </a>
        </div>
      <br/>
      <div className="header" id="nftheader">
        <span>EXPLORERS</span>
      </div>
      <br/>
      <div className="img-containerf">
         <div  className={`image-container1`}ref={imageRef}>
     
      <img className={`image ${isExpanded ? 'expanded' : ''}`}   src={process.env.PUBLIC_URL + '/Media/im3.png'} alt="Image" />
      <img className={`image ${isExpanded ? 'expanded' : ''}`}  src={process.env.PUBLIC_URL + '/Media/im1.png'} alt="Image" />
      <img className={`image ${isExpanded ? 'expanded' : ''}`}   src={process.env.PUBLIC_URL + '/Media/im2.png'} alt="Image" />
      
    </div>
    </div>
    
    <div className="header3" style = {{marginTop:"3%",fontFamily: 'Benzin-Medium',color:'#ddd',textAlign:'center'}}>PREMIUM EXPERIENCE</div>
      <div className="header"style = {{fontFamily: 'Benzin-Bold', color:'#fff'}}>
      Explore the Xade Universe 
      </div>
      <br />
      <div className="topText"style = {{fontFamily: 'Regular',color: '#B3C5BB'}}>
      Xade Explorers👽 are a utility based NFT collection with 1000 Unique Xade Explorers which help you unleash the full potential a new era of finance in the Xade Mobile Application including reduced fees,increased rewards and exclusive access to running liquidation bots, attending Xade Unleashed events, taking part in Xade private sales and much more!
      </div>
    <br/>
    <div className="CardbuttonContainer">
	  <button onClick={(e)=>{e.preventDefault();window.open("https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=NGtibGlvZ2hhaGw4c2xvN2xhODliMm5ybGcga2lyYW5hbGV4Y0Bt&tmsrc=kiranalexc%40gmail.com ", '_blank')}}>Set Reminder</button>
	  </div>
    <br/>
    <br/>
    <NftCarousel/><br/><br/><br/>
    <div className="boxfather">
                    <div className="betterbox betterbox7">

                        <div className="headerImg" >
                          <img src={process.env.PUBLIC_URL + '/Media/xadecoin1.png'}/>
                        </div>
                        <br />
                        <br />
                        <div className="circle"></div>
                        <div className="mainText">
                          <h3>Earn Xade Coins </h3>
                          Earn Upto 2x Xade Coin rewards on every transaction you do on the Xade Platform <br/><br/></div>
                    </div>

                    <div className="betterbox betterbox7">

                    <div className="headerImg" >
                          <img src={process.env.PUBLIC_URL + '/Media/clabot2.png'}/>
                        </div>
                        <br />
                        <br />        
                        <h3 style={{width:"100%"}}>Run Liquidation Bots</h3>
                        <div className="mainText">Exclusive Access to run liquidation bots for DeriveX : The Derivatives Exchange powering Xade</div>
                    </div>

                    <div className="betterbox betterbox7">

                    <div className="headerImg" >
                          <img src={process.env.PUBLIC_URL + '/Media/discount2.png'}/>
                        </div>
                        <br />
                        <br />        
                        <h3 style={{width:"100%"}}>Reduced Fees</h3>
                        <div className="mainText">Get upto 5% reduced fees on the Xade Platform </div>
                    </div>

                    <div className="betterbox betterbox7">

                    <div className="headerImg" >
                          <img src={process.env.PUBLIC_URL + '/Media/ud.png'}/>
                        </div>
                        <br />
                        <br />
                        <div className="circle"></div>
                        <h3 style={{width:"100%"}}>Exclusive Events </h3>
                        <div className="mainText">Exclusive VIP Access to Xade Unleashed Events Globally <br/><br/></div>
                    </div>

                    <div className="betterbox betterbox7">

                    <div className="headerImg" >
                          <img src={process.env.PUBLIC_URL + '/Media/listj.png'}/>
                        </div>
                        <br />
                        <br />       
                        <h3 style={{width:"100%"}}>Premier Access</h3> 
                        <div className="mainText">Get access to private and pre sales of Xade NFTs and Tokens</div>
                    </div>

                    <div className="betterbox betterbox7">

                    <div className="headerImg" >
                          <img src={process.env.PUBLIC_URL + '/Media/discord.png'}/>
                        </div>
                        <br />
                        <br />        
                        <h3 style={{width:"100%"}}>Community Roles</h3> 
                        <div className="mainText">Get exclusive community roles, follow backs, exclusive beta access and other community perks</div>
                    </div>


                </div>
    </div>
  );
};

export default App;